import React, { Suspense, useEffect } from 'react';
import { Navigate, useNavigate, Routes, Route } from 'react-router-dom';
import { Security, useOktaAuth } from '@okta/okta-react';
import type { RestoreOriginalUriFunction } from '@okta/okta-react/bundles/types/OktaContext';
import { OktaAuth } from '@okta/okta-auth-js';
import Navigation from './components/Navigation';
import config from './utils/config';
import DelayLoader from './components/DelayLoader';
import { throttle } from 'lodash';
import queryString from 'query-string';
import urlcat from 'urlcat';
import { datadogLogs } from '@datadog/browser-logs';
import allowReturnToUrl from 'pages/Login/allowReturnToUrl';
import { AlertProvider } from '@bw/alloy-react';

// Lazy load routes
const Login = React.lazy(() => import('./pages/Login'));
const AccountAdminBreakGlass = React.lazy(
  () => import('./pages/Sso/AccountAdminBreakGlass'),
);
const MultipleIdpBreakGlass = React.lazy(
  () => import('./pages/Sso/MultipleIdpSelect'),
);
const Enrollment = React.lazy(
  () => import('./pages/MFA/Enrollment/Enrollment'),
);
const Verify = React.lazy(() => import('./pages/MFA/Verify'));
const ForgotPassword = React.lazy(() => import('./pages/ForgotPassword'));
const NewPassword = React.lazy(() => import('./pages/NewPassword'));
const Registration = React.lazy(() => import('./pages/Registration'));
const ClockErrorPage = React.lazy(() => import('./pages/ClockErrorPage'));
const NotFound = React.lazy(() => import('./pages/NotFound'));
const oktaAuth = new OktaAuth(config.oidc);

const checkLoggedIn = throttle(async () => {
  const hasSession = await oktaAuth.session.exists();
  if (!hasSession || oktaAuth.isLoginRedirect()) {
    return;
  }
  const { tokens } = await oktaAuth.token.getWithoutPrompt({
    responseType: ['id_token', 'token'],
  });
  localStorage.removeItem('attempts');
  await oktaAuth.handleLoginRedirect(tokens);
}, 10000);

const DashboardRedirect = () => {
  useEffect(() => {
    (async () => {
      const returnTo = sessionStorage.getItem('returnTo');
      if (returnTo) {
        try {
          const returnToUrl = new URL(returnTo);
          if (allowReturnToUrl(returnToUrl)) {
            const originalUri = returnTo || oktaAuth.getOriginalUri();
            const { authType } = JSON.parse(
              sessionStorage.getItem('loginData') || '{}',
            );
            if (authType !== 'popup') {
              await oktaAuth?.options?.restoreOriginalUri?.(
                oktaAuth,
                originalUri,
              );
            }
          } else {
            datadogLogs.logger.warn(`Unlisted returnTo url`, {
              data: {
                invalidReturnTo: returnTo,
              },
            });
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.warn('Invalid returnTo url', returnTo);
        }
      }
    })();
  }, []);
  return null;
};

const SlashRedirect = () => {
  const { authState } = useOktaAuth();

  if (!authState) return <DelayLoader delay={1000} />;
  return authState.isAuthenticated ? (
    <DashboardRedirect />
  ) : (
    <Navigate to="/login" />
  );
};

// Redirect undefined routes to the /login page and any file requests to NotFound
const UnknownPathRedirect = () => {
  const { authState } = useOktaAuth();
  if (!authState) return <DelayLoader delay={1000} />;

  // check to see if the last entry in the url has a . in it for a file request
  // we are using the array methods here to avoid a dependency on the path package
  // since the updated craco doesnt include it by default
  const currentURL: URL = new URL(window.location.href);

  let isThereAFileExtension: number;
  if (currentURL.pathname !== '') {
    // eslint-disable-next-line
    isThereAFileExtension = currentURL?.pathname.split('/').pop()?.indexOf('.');
  }
  // if there is no file extension, redirect to login
  if (isThereAFileExtension < 0) {
    return <Navigate to="/login" />;
  }
  // if there IS a file extension, redirect to not found
  return <NotFound />;
};

type PreloadableComponent<T = unknown> = React.ComponentType<T> & {
  preload?: () => void;
};

type ModifiedRouteProps = {
  element?: PreloadableComponent;
  secure?: boolean;
  path?: string;
};

// these are needed for the RouteLink component
export const routeDefinitions: ModifiedRouteProps[] = [
  { path: '/', element: SlashRedirect },
  { path: '/forgot-password', element: ForgotPassword },
  { path: '/clock-error', element: ClockErrorPage },
  { path: '/new-password', element: NewPassword },
  { path: '/registration', element: Registration },
  { path: '/login', element: Login },
  { path: '/login/mfa/enrollment', element: Enrollment },
  { path: '/login/sso/admin', element: AccountAdminBreakGlass },
  { path: '/login/sso/choose', element: MultipleIdpBreakGlass },
  { path: '/index.html', element: () => <Navigate to="/login" /> },
  { path: '/logout', element: () => <Navigate to="/login" /> },
  { element: UnknownPathRedirect },
];

const PassportRoutes = () => {
  const navigate = useNavigate();
  const onAuthRequired = () => {
    navigate('/login');
  };

  useEffect(() => {
    if (window.location.hostname.startsWith('review')) {
      // Save the review app ID so the server knows where to redirect
      const reviewId = window.location.pathname.split('/')[1];
      sessionStorage.setItem('review', `/${reviewId}`);
    }
  }, []);

  useEffect(() => {
    checkLoggedIn();
    // if you come directly to the page with the PWD search parameter
    if (window.location.search.includes('pwd')) {
      // strip out the pwd (but keep anything else!) and redirect to login
      const queryParams = { ...queryString.parse(window.location.search) };
      const restOfQueryParams = { ...(delete queryParams.pwd && queryParams) };
      navigate(urlcat(`/login`, restOfQueryParams));
    }
    window.addEventListener('focus', checkLoggedIn);
    return () => {
      window.removeEventListener('focus', checkLoggedIn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AlertProvider>
      <Security
        oktaAuth={oktaAuth}
        onAuthRequired={onAuthRequired}
        restoreOriginalUri={
          config.oidc.restoreOriginalUri as RestoreOriginalUriFunction
        }
      >
        <Navigation />

        <Suspense fallback={<DelayLoader delay={1000} />}>
          <Routes>
            <Route path="/" element={<SlashRedirect />} />
            <Route path="/login" element={<Login />} />
            <Route path="/login/mfa/enrollment" element={<Enrollment />} />
            <Route path="/login/mfa/verify" element={<Verify />} />
            <Route
              path="/login/sso/admin"
              element={<AccountAdminBreakGlass />}
            />
            <Route
              path="/login/sso/choose"
              element={<MultipleIdpBreakGlass />}
            />
            <Route path="/login/*" element={<UnknownPathRedirect />} />
            <Route path="/index.html" element={<Navigate to="/login" />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/forgot-password/*"
              element={<UnknownPathRedirect />}
            />
            <Route path="/logout" element={<Navigate to="/login" />} />
            <Route path="/clock-error" element={<ClockErrorPage />} />
            <Route path="/clock-error/*" element={<UnknownPathRedirect />} />
            <Route path="/new-password" element={<NewPassword />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="*" element={<Navigate to="/login" />} />
            {/* Example of how you would do protected routes with
            the new Okta React SDK and React Router */}
            {/* <Route path='/protectedRoutePath' element={<RequiredAuth />}>
                  <Route path='' element={<ProtectedRouteComponent />} />
                </Route> */}
          </Routes>
        </Suspense>
      </Security>
    </AlertProvider>
  );
};
export default PassportRoutes;
