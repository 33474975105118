import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import '@bw/bw-styles/dist/bwStyles.css';
import './global.scss';

import PassportRoutes from './Routes';
import ApiProvider from './ApiProvider';
import './datadog';

/** Top level application component. */
const App = () => (
  <BrowserRouter basename={process.env.PUBLIC_URL || '/'}>
    <ApiProvider>
      <PassportRoutes />
    </ApiProvider>
  </BrowserRouter>
);
export default App;
