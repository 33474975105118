import { Loader, Container } from '@bw/alloy-react';
import React, { useEffect, useState } from 'react';

export type DelayLoaderProps = {
  delay: number;
};

const DelayLoader: React.FC<DelayLoaderProps> = ({ delay }) => {
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    const id = setTimeout(() => setShowLoader(true), delay);
    return () => clearTimeout(id);
  });

  if (!showLoader) {
    return null;
  }

  return (
    <Container breakpoint="sm" classes="centered-container">
      <Loader />
    </Container>
  );
};

export default DelayLoader;
