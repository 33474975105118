import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { AdminNav } from '@bw/alloy-react';
import './Navigation.scss';

/** Basic navigation component that shows links in the nav bar. */
const Navigation: React.FC = () => {
  const { authState, oktaAuth } = useOktaAuth();
  if (authState?.isAuthenticated || oktaAuth?.isLoginRedirect()) {
    return null;
  }

  return (
    <AdminNav
      title="Bandwidth"
      items={[
        {
          dataBw: 'subItem1',
          label: 'Privacy Notice',
          to: 'https://bandwidth.com/privacy',
        },
        {
          dataBw: 'subItem2',
          label: 'Support',
          to: 'https://support.bandwidth.com/home',
        },
        {
          dataBw: 'subItem3',
          label: 'Payments',
          to: 'https://pay.bandwidth.com/',
        },
        {
          dataBw: 'subItem4',
          label: 'Service Status',
          to: 'https://status.bandwidth.com/',
        },
      ]}
      mainContentId="top-nav"
    />
  );
};

export default Navigation;
